/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #F7F7F7;
}

@font-face {
  font-family: 'ArchivoNarrow-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/ArchivoNarrow-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-SemiBold';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Raleway-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Raleway-Regular.ttf') format('truetype');
}